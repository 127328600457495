














































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ApiResponse } from '../../../api'
import uiState from '../../../ui-state'
import { loadFNMRoutes, loadSFDRoutes } from '../../../router'

export default Vue.extend({
  data() {
    return {
      uiState: uiState(['initial', 'loading', 'error']),
      code: ''
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    last2PhoneDigits(): string {
      return this.user.phone.substring(this.user.phone.length - 2)
    },

    isLoading(): boolean {
      return this.uiState.is('loading')
    },

    hasError(): boolean {
      return this.uiState.is('error')
    }
  },

  methods: {
    ...mapActions('auth', ['verify2FA']),

    onSubmit(this: any) {
      this.uiState.set('loading')
      this.verify2FA(this.code).then((response: ApiResponse) => {
        if (response.isSuccessful) {
          this.uiState.reset()
          const { user } = response.data

          if (user.organization.type === 'fnm') {
            loadFNMRoutes()
          } else {
            loadSFDRoutes()
          }
          if (this.$route.query.redirect) {
            return this.$router.push(this.$route.query.redirect)
          }

          return this.$router.push({ name: 'firstDashboard' })
        }

        let error = 'Le code OTP est invalide'

        if (response.status === 409) {
          switch (response.data.code) {
            case 'already_verified':
              error = 'Code à déjà été utilisé pour ce compte'
              break
          }
        }

        this.uiState.set('error')
        this.$refs.validator.setErrors({
          code: error
        })
      })
    }
  }
})
